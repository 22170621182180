import { useState, useEffect } from 'react'
import { useNavigate, useParams, Outlet } from 'react-router'
import { useQuery } from 'react-query'
import { faUserCircle } from '@fortawesome/free-regular-svg-icons'

import Formulaire from './Formulaire'
import Article from './Article'
import Nav from './Nav'
import { Spinner } from './Spinner'
import { Button } from './Button'

import { useTranslations } from '../hooks'
import translations from '../Translations.js'
import styles from './FicheProduit.module.scss'

export default function FicheProduit() {
  const t = useTranslations(translations)
  const navigate = useNavigate()

  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const { id } = useParams()
  const [item, setItem] = useState(null)

  const { data: user } = useQuery('user')

  useEffect(() => {
    if (user !== undefined && user !== false && !isAuthenticated) {
      setIsAuthenticated(true)
    } else if (isAuthenticated && user === false) {
      setIsAuthenticated(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(
    () =>
      fetch(`/api/products`)
        .then(response => response.json())
        .then(data => setItem(data.find(value => value.id === Number(id)))),
    [id]
  )

  return (
    <div className={styles.content}>
      <Nav />
      {item ? (
        <>
          <Article item={item} />
          {!isAuthenticated ? (
            <div className={styles.card}>
              <h3>{t('access.title')}</h3>
              <div>{t('access.text')}</div>
              <div className={styles.buttons}>
                <Button
                  className="btn modal-toggle"
                  onClick={() => navigate('login')}
                  icon={faUserCircle}
                >
                  {t('access.signIn')}
                </Button>
                <div className={styles.signup}>
                  {t('access.noAccount')}
                  <div className="link" onClick={() => navigate('signup')}>
                    {t('access.signUp')}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <Formulaire itemName={item?.name} />
          )}
        </>
      ) : (
        <Spinner />
      )}
      <Outlet />
    </div>
  )
}
