import React from 'react'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import styles from './IconButton.module.scss'

export const IconButton = React.forwardRef(
  ({ icon, className, ...props }, ref) => (
    <button
      ref={ref}
      className={classNames(className, styles.button)}
      {...props}
    >
      <FontAwesomeIcon icon={icon} size="lg" />
    </button>
  )
)
