import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons'
import { useTranslations } from '../hooks'
import translations from '../Translations.js'

export default function Nav() {
  const t = useTranslations(translations)
  return (
    <nav>
      <Link to="/" className="link-back">
        <FontAwesomeIcon icon={faArrowCircleLeft} size={'lg'} /> {t('navLink')}
      </Link>
    </nav>
  )
}
