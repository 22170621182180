import { useCallback, useEffect, useRef, useState } from 'react'

export const useTranslations = translations => {
  const lang = navigator.language.substr(0, 2)
  const language = ['fr', 'en'].includes(lang) ? lang : 'en'

  const getTranslatedString = useCallback((translations, key, ...rest) => {
    if (rest.length === 0) return translations[key]
    return getTranslatedString(translations[key], ...rest)
  }, [])

  return useCallback(
    key => getTranslatedString(translations, language, ...key.split('.')),
    [language, translations, getTranslatedString]
  )
}

export const useOutsideClick = callback => {
  const wrapperRef = useRef(null)

  useEffect(() => {
    const handleOutsideClick = e => {
      if (wrapperRef.current && !wrapperRef.current.contains(e.target))
        callback(e)
    }

    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [callback, wrapperRef])

  return wrapperRef
}

export const useModal = () => {
  const [isShowing, setIsShowing] = useState(false)

  function toggle() {
    setIsShowing(!isShowing)
  }

  return {
    isShowing,
    toggle,
  }
}
