import { useTranslations } from '../hooks'
import translations from '../Translations'

export const Legal = () => {
  const t = useTranslations(translations)

  return (
    <div className="legal-content">
      <h1>{t('legal')}</h1>
      <h2>Edition & Vente</h2>
      <div>
        Raison sociale : <b>AFRICA BUSINESS MARKETPLACE</b> <br />
        Forme juridique : <b>SAS</b> <br />
        Capital social : 323 662 € <br />
        Adresse du siège social :{' '}
        <b>54 Rue de Londres, 75008 – Paris, France</b> <br />
        Numéro d’inscription au registre du commerce et des sociétés (RCS) : 887
        941 839 <br />
        Numéro de TVA intracommunautaire : FR 838 879 418 39 <br />
        Directeur de la publication : Wilfried ASSOKO <br />
      </div>
      <h2>Hébergeur</h2>
      <div>
        Raison sociale : <b>PEPPERBAY SYSTEM</b> <br />
        Forme juridique : <b>SAS</b> <br />
        Capital social : 20 000 € <br />
        Adresse du siège social :{' '}
        <b>40 RUE JEAN MONNET 68200 MULHOUSE, FRANCE</b> <br />
        Numéro d’inscription au registre du commerce et des sociétés (RCS) :
        Mulhouse B 850 735 382 <br />
        Numéro de TVA intracommunautaire : FR 678 507 353 82 <br />
      </div>
      <h2>Crédits</h2>
      <div>
        Réalisation et développement : Groupe{' '}
        <a href="http://www.pepperbay.fr">Pepperbay.fr</a>
      </div>
    </div>
  )
}
