import { useMemo, useEffect, useState } from 'react'
import { Outlet } from 'react-router'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import { groupBy } from 'lodash'

import { Button } from './Button'
import { Image } from './Image'
import { useTranslations } from '../hooks'

import styles from './Home.module.scss'
import translations from '../Translations'

const PAGE_SIZE = 8

export default function Home() {
  const t = useTranslations(translations)
  const [page, setPage] = useState({})

  const { data } = useQuery('products', () =>
    fetch(`/api/products`).then(res => res.json())
  )

  const productByCategories = useMemo(() => {
    return groupBy(data, 'categoryName')
  }, [data])

  useEffect(
    () =>
      setPage(
        Object.fromEntries(
          Object.keys(productByCategories).map(name => [name, 1])
        )
      ),
    [productByCategories]
  )

  return (
    <div className={styles.home}>
      <h1>{t('navLink')}</h1>
      {data ? (
        Object.entries(productByCategories).map(([name, products]) => (
          <>
            <h2>
              {name} ({products.length ?? 0})
            </h2>
            <div className={styles.category}>
              {products
                .slice(PAGE_SIZE * (page[name] - 1), PAGE_SIZE * page[name])
                .map(product => (
                  <div key={`product-${product.id}`} className={styles.card}>
                    {product.id && (
                      <Link to={`/product/${product.id}`}>
                        <div className={styles.image}>
                          <Image image={product.image_url} />
                        </div>
                        <div className={styles.name}> {product.name}</div>
                      </Link>
                    )}
                  </div>
                ))}
            </div>
            <div className={styles.pagination}>
              <Button
                onClick={() =>
                  setPage(page => ({
                    ...page,
                    [name]: page[name] - 1,
                  }))
                }
                primary
                secondary
                disabled={page[name] === 1}
              >
                {'<'}
              </Button>
              {products &&
                [...Array(Math.ceil(products.length / PAGE_SIZE)).keys()].map(
                  pageNumber => (
                    <Button
                      key={pageNumber}
                      onClick={() =>
                        setPage(page => ({
                          ...page,
                          [name]: pageNumber + 1,
                        }))
                      }
                      primary
                      secondary={page[name] !== pageNumber + 1}
                    >
                      {pageNumber + 1}
                    </Button>
                  )
                )}
              <Button
                onClick={() =>
                  setPage(page => ({
                    ...page,
                    [name]: page[name] + 1,
                  }))
                }
                primary
                secondary
                disabled={page[name] >= products?.length / PAGE_SIZE}
              >
                {'>'}
              </Button>
            </div>
          </>
        ))
      ) : (
        <p>Loading...</p>
      )}
      <Outlet />
    </div>
  )
}
