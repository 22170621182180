const translations = {
  fr: {
    loading: 'Chargement...',
    navLink: 'Catalogue',
    product: {
      features: 'Caractéristiques',
    },
    access: {
      title: 'Accès à votre compte',
      text: 'Pour toute demande d’information, l’accès à votre compte est indispensable.',
      alreadyAccount: 'Vous avez déjà un compte ?',
      signIn: 'Je me connecte',
      noAccount: 'Vous n’avez pas de compte ?',
      signUp: 'Je crée mon compte pro',
      back: 'Retour',
    },
    loginForm: {
      title: ' Je me connecte',
      subTitle: ' Déjà client ?',
      email: {
        label: 'Email',
      },
      submit: 'Je récupère mon mot de passe',
      loginOK: "Un mail d'authentification vous a été envoyé.",
      loginKO: "Echec lors de l'autentification, veuillez réessayer.",
    },
    signUpForm: {
      title: 'Je crée mon compte pro',
      lastname: {
        label: 'Nom',
      },
      firstname: {
        label: 'Prénom',
      },
      company: {
        label: 'Société',
      },
      phone: {
        label: 'Téléphone',
      },
      email: {
        label: 'Email',
      },
      submit: 'Valider',
      registerOK: 'Votre compte a bien été créé. Vous pouvez vous connecter.',
      registerKO:
        'Echec lors de la création, veuillez vérifier vos informations.',
    },
    mailForm: {
      title: "Besoin d'informations supplémentaires ?",
      subTitle:
        'Remplissez le formulaire ci-dessous et notre équipe vous renseignera dans les plus brefs délais.',
      order: {
        label: 'Date de commande',
      },
      delivery: {
        label: {
          date: 'Date de livraison',
          place: 'Lieu de livraison',
        },
      },
      wish: {
        label: 'Je souhaite :',
        option1: 'Recevoir un devis et passer une commande',
        option2: "Être accompagné dans l'identification de mon besoin",
      },
      message: 'Décrivez votre besoin',
      submit: 'Contactez-nous',
      sentOK: 'Message envoyé.',
      sentKO: "Échec lors de l'envoi du message.",
    },
    legal: 'Mentions Légales',
  },
  en: {
    loading: 'Loading...',
    navLink: 'Product list',
    product: {
      features: 'Features',
    },
    access: {
      title: 'Access to your account',
      text: 'For any information request, access to your account is mandatory.',
      alreadyAccount: 'Already an account ?',
      signIn: 'Sign in',
      noAccount: 'No account ?',
      signUp: ' Sign up here',
      back: 'Back',
    },
    loginForm: {
      title: 'Sign in',
      subTitle: 'Already client ?',
      email: {
        label: 'Email',
      },
      submit: 'Recover my password',
      loginOK: 'An authentication email has been sent to you.',
      loginKO: 'An error occured, please try again.',
    },
    signUpForm: {
      title: 'Sign up',
      lastname: {
        label: 'Lastname',
      },
      firstname: {
        label: 'Firstname',
      },
      company: {
        label: 'Company',
      },
      phone: {
        label: 'Phone number',
      },
      email: {
        label: 'E-mail',
      },
      submit: 'Sign up',
      registerOK: 'Your account has been created. You can now login',
      registerKO:
        'An error occured, please verify your information and try again.',
    },
    mailForm: {
      title: 'Need more information ?',
      subTitle:
        'Complete the form below and our team will help you as soon as possible.',
      order: {
        label: 'Date of the order',
      },
      delivery: {
        label: {
          date: 'Date of the delivery',
          place: 'Place of delivery ',
        },
      },
      wish: {
        label: 'I should like :',
        option1: 'To receive a quote and ordering',
        option2: 'To work with you to identify your need',
      },
      message: 'Describe your need',
      submit: 'Contact us',
      sentOK: 'Message Sent.',
      sentKO: 'Message failed to send.',
    },
    legal: 'Legal disclaimer',
  },
}

export default translations
