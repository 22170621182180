import React, { useRef, useEffect, useState, useCallback } from 'react'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { Card } from '../Card'

import styles from './Modal.module.scss'

export const Modal = ({ className, children, title, onClose, ...props }) => {
  const backgroundRef = useRef()
  const [isMouseDownOutside, setIsMouseDownOutside] = useState(false)

  const close = useCallback(onClose, [onClose])

  const onOutsideMouseDown = ({ target }) =>
    setIsMouseDownOutside(backgroundRef.current === target)

  const onOutsideMouseUp = ({ target }) =>
    isMouseDownOutside && backgroundRef.current === target && close()

  const onEscapeKeyDown = useCallback(
    event => event.keyCode === 27 && close(),
    [close]
  )

  useEffect(() => {
    document.addEventListener('keydown', onEscapeKeyDown, false)

    return () => {
      document.removeEventListener('keydown', onEscapeKeyDown, false)
    }
  }, [onEscapeKeyDown])

  return (
    <div
      ref={backgroundRef}
      className={styles.background}
      onMouseDown={onOutsideMouseDown}
      onMouseUp={onOutsideMouseUp}
      data-testid="modal"
    >
      <Card className={classNames(styles.modal, className)} {...props}>
        {title && <Header closeFunc={close}>{title}</Header>}
        <div id="modal-tooltip-root"></div>
        <Content>{children}</Content>
      </Card>
    </div>
  )
}

const Header = ({ children, closeFunc }) => (
  <div className={styles.header}>
    <button className={styles.close} onClick={closeFunc} name="Close">
      <FontAwesomeIcon icon={faTimes} size="lg" />
    </button>
    <h4>{children}</h4>
  </div>
)

const Content = props => <div className={styles.content} {...props} />

export const Footer = props => <div className={styles.footer} {...props} />
