import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { faUserCircle } from '@fortawesome/free-regular-svg-icons'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router'

import { Modal } from './Modal'
import { Button } from './Button'
import { useTranslations } from '../hooks/'

import translations from '../Translations.js'
import styles from './FormulaireModal.module.scss'

export default function FormulaireInscription() {
  const t = useTranslations(translations)
  const navigate = useNavigate()

  const [value, setValue] = useState({})

  const { data } = useQuery('user')

  useEffect(() => {
    if (data) {
      navigate('..', { replace: true })
    }
  }, [navigate, data])

  const onChange = field => e => setValue({ ...value, [field]: e.target.value })

  const handleSubmit = event => {
    event.preventDefault()
    fetch('/api/register', {
      method: 'POST',
      body: JSON.stringify({ ...value }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(response => {
      if (response.ok) {
        toast.success(t('signUpForm.registerOK'), { theme: 'colored' })
      } else {
        toast.error(t('signUpForm.registerKO'), { theme: 'colored' })
      }
      navigate('..', { replace: true })
    })
  }

  return (
    <Modal
      title={t('signUpForm.title')}
      onClose={() => navigate('..', { replace: true })}
      className={styles.modal}
    >
      <form id="sign-up-form" onSubmit={handleSubmit} className={styles.form}>
        <div className="form-group" onChange={onChange('lastname')}>
          <label htmlFor="lastname">{t('signUpForm.lastname.label')}</label>
          <input type="text" className="form-control" />
        </div>
        <div className="form-group" onChange={onChange('firstname')}>
          <label htmlFor="firstname">{t('signUpForm.firstname.label')}</label>
          <input type="text" className="form-control" />
        </div>

        <div className="form-group" onChange={onChange('company')}>
          <label htmlFor="company">{t('signUpForm.company.label')}</label>
          <input type="text" className="form-control" />
        </div>

        <div className="form-group" onChange={onChange('phone')}>
          <label htmlFor="phone">{t('signUpForm.phone.label')}</label>
          <input type="tel" className="form-control" />
        </div>

        <div className="form-group" onChange={onChange('email')}>
          <label htmlFor="email">{t('signUpForm.email.label')}</label>
          <input type="email" className="form-control" />
        </div>
        <Button
          type="submit"
          primary
          disabled={
            !value.lastname ||
            !value.firstname ||
            !value.company ||
            !value.phone ||
            !value.email
          }
        >
          {t('signUpForm.submit')}
        </Button>
        <div className="link" onClick={() => navigate(-1)}>
          {t('access.back')}
        </div>
        <hr className={styles.separator} />
        <div className={styles.switch}>{t('access.alreadyAccount')}</div>
        <Button
          primary
          onClick={() => navigate('../login', { replace: true })}
          icon={faUserCircle}
        >
          {t('access.signIn')}
        </Button>
      </form>
    </Modal>
  )
}
