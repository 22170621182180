import { useState } from 'react'
import { toast } from 'react-toastify'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

import { useTranslations } from '../hooks'

import { Button } from './Button'

import translations from '../Translations.js'
import styles from './Formulaire.module.scss'

export default function Formulaire({ itemName }) {
  const t = useTranslations(translations)
  const [value, setValue] = useState({
    souhait: t('mailForm.wish.option1'),
  })

  const onChange = field => e => setValue({ ...value, [field]: e.target.value })

  const handleSubmit = event => {
    event.preventDefault()
    fetch('/api/mail', {
      method: 'POST',
      body: JSON.stringify({ ...value, itemName }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(response => {
      if (response.ok) {
        toast.success(t('mailForm.sentOK'), { theme: 'colored' })
      } else {
        toast.error(t('mailForm.sentKO'), { theme: 'colored' })
      }
    })
  }

  return (
    <form id="contact-form" onSubmit={handleSubmit} method="POST">
      <div className={styles.card}>
        <h3>{t('mailForm.title')}</h3>
        <div>{t('mailForm.subTitle')}</div>
        <div className={styles.row}>
          <div className="form-group" onChange={onChange('dateInit')}>
            <label htmlFor="date-init">{t('mailForm.order.label')}</label>
            <input
              type="date"
              id="start"
              name="date-init"
              placeholder={Date()}
              className="form-control"
            />
          </div>

          <div className="form-group" onChange={onChange('dateLivraison')}>
            <label htmlFor="date-livraison">
              {t('mailForm.delivery.label.date')}
            </label>
            <input
              type="date"
              id="end"
              name="date-livraison"
              className="form-control"
            />
          </div>

          <div className="form-group" onChange={onChange('lieu')}>
            <label htmlFor="lieu"> {t('mailForm.delivery.label.place')} </label>
            <input
              type="text"
              placeholder={t('mailForm.delivery.label.place')}
              className="form-control"
            />
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="souhait"> {t('mailForm.wish.label')} </label>
          <select
            name="souhait"
            size="1"
            onChange={onChange('souhait')}
            className="form-control"
          >
            <option>{t('mailForm.wish.option1')}</option>
            <option>{t('mailForm.wish.option2')}</option>
          </select>
        </div>

        <div className="form-group" onChange={onChange('message')}>
          <label htmlFor="message">Message</label>
          <textarea
            className="form-control"
            rows="5"
            placeholder={t('mailForm.message')}
          />
        </div>
        <Button type="submit" icon={faEnvelope} className={styles.submit}>
          {t('mailForm.submit')}
        </Button>
      </div>
    </form>
  )
}
