import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router'
import { useQuery } from 'react-query'

import { Modal } from './Modal'
import { useTranslations } from '../hooks'
import translations from '../Translations.js'
import { Button } from './Button'

import styles from './FormulaireModal.module.scss'

export default function FormulaireConnexion() {
  const t = useTranslations(translations)
  const navigate = useNavigate()

  const [value, setValue] = useState({})

  const { data } = useQuery('user')

  useEffect(() => {
    if (data) {
      navigate('..', { replace: true })
    }
  }, [navigate, data])

  const onChange = field => e => setValue({ ...value, [field]: e.target.value })

  const handleSubmit = event => {
    event.preventDefault()
    fetch('/api/connexion', {
      method: 'POST',
      body: JSON.stringify({ ...value }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(response => {
      if (response.ok) {
        toast.success(t('loginForm.loginOK'), { theme: 'colored' })
      } else {
        toast.error(t('loginForm.loginKO'), { theme: 'colored' })
      }
      navigate('..', { replace: true })
    })
  }

  return (
    <Modal
      title={t('loginForm.title')}
      onClose={() => navigate('..', { replace: true })}
      className={styles.modal}
    >
      <form id="sign-in-form" onSubmit={handleSubmit} className={styles.form}>
        <div className="form-group" onChange={onChange('email')}>
          <label htmlFor="email">{t('loginForm.email.label')}</label>
          <input type="email" name="email" className="form-control" />
        </div>
        <Button type="submit" primary disabled={!value.email}>
          {t('loginForm.submit')}
        </Button>
        <div className="link" onClick={() => navigate(-1)}>
          {t('access.back')}
        </div>
        <hr className={styles.separator} />
        <div className={styles.switch}>{t('access.noAccount')}</div>
        <Button
          primary
          onClick={() => navigate('../signup', { replace: true })}
        >
          {t('access.signUp')}
        </Button>
      </form>
    </Modal>
  )
}
