import { useState } from 'react'
import { Routes, Route, Link } from 'react-router-dom'
import { useNavigate } from 'react-router'
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
} from 'react-query'
import { ToastContainer } from 'react-toastify'
import { useOutsideClick, useTranslations } from './hooks'

import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { faUserCircle } from '@fortawesome/free-regular-svg-icons'

import { Button } from './components/Button'
import FormulaireConnexion from './components/FormulaireConnexion'
import FormulaireInscription from './components/FormulaireInscription'
import Home from './components/Home'
import FicheProduit from './components/FicheProduit'
import { IconButton } from './components/IconButton'
import { Card } from './components/Card'
import { Legal } from './components/Legal'

import logo from './logo.png'
import './App.scss'
import 'react-toastify/dist/ReactToastify.css'

import translations from './Translations.js'

const queryClient = new QueryClient()

function Example() {
  const t = useTranslations(translations)
  const navigate = useNavigate()

  const [showMenu, setShowMenu] = useState(false)
  const ref = useOutsideClick(() => setShowMenu(false))
  const { isLoading, data } = useQuery(
    'user',
    () =>
      fetch('/api/me')
        .then(res => res.json())
        .catch(() => false),
    {
      retry: (failureCount, error) => {
        return error.response?.status >= 500
      },
    }
  )

  const mutation = useMutation(
    formData => {
      return fetch('/api/logout', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
    },
    {
      onSuccess: () => queryClient.invalidateQueries('user'),
    }
  )

  const onClick = e => {
    e.preventDefault()
    mutation.mutate()
  }

  if (isLoading) return t('loading')

  return (
    <div className="App-user" ref={ref}>
      <IconButton
        icon={faUserCircle}
        secondary
        className="btn-user"
        onClick={() => setShowMenu(a => !a)}
      />
      {showMenu && (
        <Card className="user-menu">
          <div className="title">{data ? data.name : 'Invité'}</div>
          <div className="card-content">
            <Button
              cancel
              secondary
              onClick={data ? onClick : () => navigate('login')}
              icon={faSignOutAlt}
            >
              {data ? 'Déconnexion' : 'Connexion'}
            </Button>
          </div>
        </Card>
      )}
    </div>
  )
}

const App = () => {
  const t = useTranslations(translations)

  return (
    <QueryClientProvider client={queryClient}>
      <header>
        <div className="App-logo">
          <Link to="/">
            <img src={logo} alt="logo" className="App-logo" />
          </Link>
        </div>
        <Example />
      </header>
      <div className="content">
        <Routes activeStyle={{ color: 'red' }}>
          <Route path="/" exact element={<Home />}>
            <Route path="login" element={<FormulaireConnexion />} />
            <Route path="signup" element={<FormulaireInscription />} />
          </Route>
          <Route path="/product/:id" element={<FicheProduit />}>
            <Route path="login" element={<FormulaireConnexion />} />
            <Route path="signup" element={<FormulaireInscription />} />
          </Route>
          <Route path="/mentions-legales" element={<Legal />} />
        </Routes>
      </div>
      <footer>
        <a href="https://sodoowo.com" target="_blank">www.sodoowo.com</a>
        <div>
          <Link to="/mentions-legales" target="_blank">{t('legal')}</Link>
          {' • '}
          <a href="https://calendly.com/sodoowo" target="_blank">Contact</a>
        </div>
        <div>Réalisé par Pepperbay</div>
      </footer>
      <ToastContainer />
    </QueryClientProvider>
  )
}
export default App
