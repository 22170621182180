import React from 'react'

import classNames from 'classnames'

import styles from './spinner.module.scss'

export const Spinner = ({ className, hidden, message }) => (
  <div className={styles.container}>
    <div className={spinnerClasses(className, hidden)} role="status">
      <span className="sr-only">Loading...</span>
    </div>
    {message && <span className={styles.message}>{message}</span>}
  </div>
)

const spinnerClasses = (className, hidden) =>
  classNames(styles.spinner, className, 'spinner-border spinner-border-sm', {
    'd-none': hidden,
  })
