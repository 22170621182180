import React from 'react'

import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Spinner } from '../Spinner'

import styles from './Button.module.scss'

const ContentComponent = ({ className, iconLeft, iconRight, children }) => (
  <div className={classNames(styles.content, className)}>
    {iconLeft && (
      <FontAwesomeIcon
        icon={iconLeft}
        className={styles['icon-left']}
        size="lg"
      />
    )}
    <span className={styles.label}>{children}</span>
    {iconRight && (
      <FontAwesomeIcon
        icon={iconRight}
        className={styles['icon-right']}
        size="lg"
      />
    )}
  </div>
)

export const Button = ({
  ariaExpanded,
  ariaHaspopup,
  dataToggle,
  icon,
  iconLeft,
  iconRight,
  isLoading,
  label,
  cancel,
  className,
  round,
  secondary,
  special,
  success,
  warning,
  size,
  children,
  type = 'button',
  ...props
}) => (
  <button
    className={buttonClasses({
      round,
      cancel,
      secondary,
      special,
      warning,
      success,
      size,
      className,
    })}
    data-toggle={dataToggle}
    aria-haspopup={ariaHaspopup}
    aria-expanded={ariaExpanded}
    type={type}
    {...props}
  >
    {isLoading && <Spinner className={styles.spinner} />}
    <ContentComponent
      className={isLoading && styles.hide}
      iconRight={iconRight}
      iconLeft={icon || iconLeft}
    >
      {label || children}
    </ContentComponent>
  </button>
)

const buttonClasses = ({
  round,
  cancel,
  className,
  secondary,
  special,
  warning,
  success,
  size,
}) =>
  classNames(
    'btn',
    styles.button,
    round && styles.round,
    'fill' === size && styles.fill,
    secondary && styles.secondary,
    (cancel && styles.cancel) ||
      (special && styles.special) ||
      (warning && styles.warning) ||
      (success && styles.success) ||
      styles.primary,
    className
  )
