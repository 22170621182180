import React from 'react'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

import { nl2br } from '../Utility'
import { useModal, useTranslations } from '../hooks'

import { Image } from './Image'
import { IconButton } from './IconButton'
import { Modal } from './Modal'

import styles from './Article.module.scss'
import translations from '../Translations'

const Article = ({ item }) => {
  const { isShowing, toggle } = useModal()
  const t = useTranslations(translations)

  return (
    <>
      <div className={styles.product}>
        <div className={styles.row}>
          <Image image={item.image_url} height="100px" width="100px" />
          <h1>{item.name}</h1>
        </div>
        <hr />
        {item?.description && (
          <div className={styles.description}>
            <div className={styles.row}>
              <h5>{t('product.features')}</h5>
              <IconButton
                icon={faInfoCircle}
                className={styles.icon}
                onClick={toggle}
              />
            </div>
            <div className={styles.text}>{nl2br(item.description)}</div>
          </div>
        )}
        {isShowing && (
          <Modal
            title={t('product.features')}
            onClose={toggle}
            className={styles.modal}
          >
            <div className={styles.text}>{nl2br(item.description)}</div>
          </Modal>
        )}
      </div>
    </>
  )
}

export default Article
