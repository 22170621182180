import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImage } from '@fortawesome/free-solid-svg-icons'

export const Image = ({ image, ...props }) =>
  image !== '' ? (
    <img src={image} alt="img" {...props} />
  ) : (
    <FontAwesomeIcon
      icon={faImage}
      size={'4x'}
      color={'#D8D8D8'}
      className="Card-Image-Icon"
      {...props}
    />
  )
